<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <div style="display: flex; width: 100%; position: absolute; position: fixed; left: 0; top: 0; margin: auto; justify-content:top; align-items:top">  <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
      <div class="modal" style="outline:0.0em solid blue; padding-top: 3vh; padding-bottom: 3vh; " > 

        <!-- :style="{ 'height': 98 * this.vhMult + 'vh'}" -->

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
          <tr>
            <td>
              <table width="100%" border=0 s>
                <tr>
                  <td width="30%">
                        <span class="primary-general-text divl" style="display: block; line-height: 15px;">Getting Tips</span> 
                  </td>
                  <td width="40%">
                    <img class="tulogo" style="height: 6vh; padding-bottom: 1vh" alt="thankU logo" src="../assets/tutxtlogo.png">
                  </td>
                  <td width="30%">
                    <div class=divr>
                 
                      <button @click="doShowHome" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                    </div>
                  </td>

                </tr>
              </table>
              
            </td>
          </tr>
         
          <tr>
            <td style="padding-top: 1vh; padding-bottom: 1vh; line-height: 0.1px">              
              <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Getting tips with thankU couldn't be simpler  &#38; tips go <u>directly</u> to you, the recipient</span> 
           </td>
          </tr>
          <tr>
            <td style="padding-top: 0vh">
              <table width="100%" border=0>
                <tr>
                  <td colspan=2 width="80%" class="divl" style="padding-top: 1vh; padding-left: 2vh; line-height: 0.1px"><span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">If you have one of either of these at your workplace..</span>
                  </td>
                </tr>
                <tr>
                  <td colspan=2 width=100% class="txtc" style="padding-top: 1vh; padding-bottom: 1vh">              
                    <img style="width: 80%" alt="thankU QR code pic 1" src="../assets/displayscombined.jpg">
                </td>
              </tr>
                <tr>
                  <td colspan=2 class="divl" style="padding-top: 1vh; padding-left: 2vh; line-height: 0.1px">              
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">.. just scan the QR code and tap the 'get tips here...' button at the bottom - have your bank details to hand and it takes less than 1 minute...</span>  
                  </td>
                </tr>
                <tr>
                  <td colspan=2 class="divl" style="padding-top: 1vh; padding-left: 2vh; line-height: 0.1px">              
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">OR</span>  
                  </td>
                </tr>
                <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 1vh">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doShowGetTipsStart" >Get free thankU badges</button>
                  </div>
                </td>
              </tr>
                <!-- <tr>
                  <td colspan=2 class="divl" style="padding-top: 1vh; padding-left: 2vh;  line-height: 0.1px">              
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">2. Tap this pre-filled link to request free thankU QR badges:  <a href="mailto:mailorders@thanku.app?subject=get thankU enquiry&amp;body=Please tell us a little about your business so we can send you the right quantity and best suited thankU QR badge media for you - for example, if you own or manage a hair salon, please tell us how many stylist seats you have.. Please also supply the address we should dispatch your thankU media to. If you manage a hotel or a car valet business, we supply the thankU QR badge media mounted on wristbands - let us know and we will send you images and agree staff quantities. All thankU QR badge media is supplied free of charge." class="text-only-button cancel" style="font-size: 100%; ">mailorders@thanku.app</a></span>  
                  </td>
                </tr> -->
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding-top: 2vh; line-height: 0.1px">
              <div class=divc><button class="text-only-button cancel" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 1.2 + 'px', }" @click="doShowMoreInfo" >more info</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
</template>

<script>

import shared from '../shared.js';

export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  data() {
    return {
      fontSizeNormal: 3.0, // vh
      fontSizeNormalInPXToUse: "",
      fontSizeNormalLineHeight: 4.0,// vh
      fontSizeNormalInPXLineHeightToUse: "",
      vhMult: 1,
    }
  },
  methods: {
    doFontSizeComputed () {
      
      this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
      this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
    
    },
    doShowGetTipsStart(){
      let returnJSON = { getTipsStartNew: true, fromScreen: "Get Tips" };
      // let returnJSON = { showGetTipsStart: true, fromScreen: "Get Tips" };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back


    },
    doShowHome(){
      // /console.log("we're off!");

      let returnJSON = { showHome: true }; 
      this.$emit('return-json', returnJSON);

    },
    doShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    doAlert(){
      // /alert("Loaded!");
    },
    doSetMetas(){

      document.title = "Let your clients tip you with their phone - with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Let your clients tip you with their phone - with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour. You clients simply scan thankU's small, elegant QR badge - no app download or account creation required. Getting tips with thankU couldn't be simpler: tips go directly to you, the recipient. If you have a thankU QR badge at your workplace, just scan the QR code and tap the 'get tips here...' button at the bottom - have your bank details to hand and it takes less than 1 minute... or tap the 'Get free thankU badges' button to have thankU send you our thankU QR badges at no cost.") ;
    
    }
    
  },
  created(){
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(this.devEnv, "Get tips " + new Date().toISOString(), this.globalPushForwardInterval);
    this.doFontSizeComputed();
    window.addEventListener('resize', this.doFontSizeComputed);
  },
  mounted(){
    this.vhMult = shared.setScreenHeightMultiplier();
  }
}
</script>

